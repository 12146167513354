<template>
  <div id="playlists" class="container-fluid">
    <h3>Playlists</h3>
    <div class="mb-2">
      <b-button
        type="button"
        title="Create New Playlist"
        variant="outline-success"
        @click="openCreatePlaylist">
        <i class="fas fa-plus"></i> Playlist
      </b-button>
    </div>

    <Vuetable
      data-path="playlists"
      pagination-path="pagination"
      ref="vuetable"
      track-by="playlistId"
      :api-url="apiUrl"
      :fields="fields"
      :http-options="httpOptions"
      :css="css.table"
      :per-page="10"
      :append-params="moreParams"
      :initial-page="initialPage"
      :query-params="{
        sort    : 'sort',
        page    : 'page',
        perPage : 'perPage'
      }"
      @vuetable:pagination-data="onPaginationData"
      @vuetable:loading="showLoader"
      @vuetable:loaded="hideLoader">

      <template
        class="text-center"
        slot="order"
        slot-scope="props">
        <select
          class="form-control"
          v-model="props.rowData.orderNo"
          @change="changeOrder(props.rowData)">
          <option
            v-for="(value, key) in orderOptions"
            :key="key"
            :value="value">
            {{ value }}
          </option>
        </select>
      </template>
      <template
        class="text-center"
        slot="bannerOrder"
        slot-scope="props">
        <select
          class="form-control"
          v-bind:class="getClass(props.rowData)"
          v-model="props.rowData.bannerOrderNo"
          @change="changeBannerOrder(props.rowData)">
          <option
            v-for="(value, key) in maxBanner"
            :key="key"
            :value="value">
            {{ value }}

          </option>
        </select>
      </template>

      <!-- Action Buttons -->
      <template
        class="text-center"
        slot="actions"
        slot-scope="props">
        <router-link :to="`/admin/playlist/view/${props.rowData.playlistId}`">
          <b-button
            class="m-1"
            title="View Playlist"
            type="button"
            size="sm"
            variant="primary">
            <b-icon icon="eye" aria-hidden="true"></b-icon>
          </b-button>
        </router-link>
        <b-button
          class="m-1"
          title="Delete Playlist"
          type="button"
          size="sm"
          variant="danger"
          @click="deletePlaylist(props.rowData)">
          <b-icon icon="trash" aria-hidden="true"></b-icon>
        </b-button>
      </template>
    </Vuetable>

    <!-- Pagination -->
    <div class="d-flex align-items-center justify-content-end">
      <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>
      <vuetable-pagination
        ref="pagination"
        @vuetable-pagination:change-page="onChangePage"
        :css="css.pagination"
      ></vuetable-pagination>
    </div>

    <!-- Modal -->
    <modals-container />

    <!-- Dialog -->
    <v-dialog />
    
    <AppLoaderAdmin :isLoading="loading" />
  </div>
</template>

<script>
  import Vue from 'vue';
  import moment from 'moment-timezone';
  import TableStyle from '../table-bootstrap-css.js';
  const CreatePlaylistModal = () => import('./CreatePlaylistModal');
  const Thumbnail = () => import('./Thumbnail');

  Vue.component('thumbnail', Thumbnail);

  export default {
    head : {
      title : {
        inner      : 'Playlist',
        separator  : '|',
        complement : 'Healthy Hiphop',
      },
    },
    data() {
      return {
        loading     : false,
        apiUrl      : process.env.VUE_APP_ROOT_API + 'api/playlists',
        moreParams  : {},
        css         : TableStyle,
        httpOptions : {
          headers : {
            'Authorization' : `Bearer ${this.$store.state.auth.accessToken}`,
          },
        },
        fields : [ {
          name       : '__slot:order',
          title      : 'Playlist Order',
          sortField  : 'order_no',
          direction  : 'asc',
          titleClass : 'text-center',
          dataClass  : 'text-center',
        }, {
          name       : '__slot:bannerOrder',
          title      : 'Banner order',
          sortField  : 'banner_order_no',
          direction  : 'asc',
          titleClass : 'text-center',
          dataClass  : 'text-center',
        }, {
          name       : 'playlistId',
          title      : 'ID',
          sortField  : 'music_playlist_id',
          direction  : 'desc',
          titleClass : 'text-center',
          dataClass  : 'text-center',
        }, {
          name       : '__component:thumbnail',
          title      : 'Thumbnail',
          titleClass : 'text-center thumbnail',
          dataClass  : 'text-center',
        }, {
          name      : 'name',
          title     : 'Name',
          sortField : 'name',
          direction : 'desc',
        }, {
          name       : 'premium',
          title      : 'Premium',
          sortField  : 'premium',
          direction  : 'desc',
          titleClass : 'text-center',
          dataClass  : 'text-center',
        }, {
          name       : 'published',
          title      : 'Published',
          sortField  : 'published',
          direction  : 'desc',
          titleClass : 'text-center',
          dataClass  : 'text-center',
        }, {
          name       : 'created',
          title      : 'Created',
          sortField  : 'created',
          direction  : 'desc',
          titleClass : 'text-center',
          dataClass  : 'text-center',
        }, {
          name       : '__slot:actions',
          title      : 'Actions',
          titleClass : 'text-center',
          dataClass  : 'text-center',
        } ],
        maxOrder     : 0,
        orderOptions : 0,
        initialPage  : parseInt(this.$route.params.page),
        maxBanner    : 0,
      }
    },
    components : {
      'AppLoaderAdmin'     : () => import('@/components/layout/AppLoaderAdmin'),
      'Vuetable'           : () => import('vuetable-2/src/components/Vuetable'),
      'VuetablePagination' :
        () => import('vuetable-2/src/components/VuetablePagination'),
      'VuetablePaginationInfo' :
        () => import('vuetable-2/src/components/VuetablePaginationInfo'),
    },

    watch : {
      '$route.params.page'(val) {
        const currPage = parseInt(this.$refs.vuetable.currentPage);
        if (val && currPage !== parseInt(val)) {
          this.$nextTick(() => {
            this.$refs.vuetable.changePage(val);
          });
        }
      },
    },
    methods : {

      /**
       * Table Data
       * @param data
       * @return {object}
       */
      transform(data) {
        this.response = data;
        data = data.data;
        this.orderOptions = data.total;
        this.maxBanner = (this.response.maxBannerNumber && this.response.maxBannerNumber.banner_order_no) ? this.response.maxBannerNumber.banner_order_no : 1;
        const transformed = {};
        transformed.playlists = [];

        transformed.pagination = {
          'total'         : data.total,
          'per_page'      : data.perPage,
          'current_page'  : data.currentPage,
          'last_page'     : data.lastPage,
          'next_page_url' : data.nextPageUrl,
          'prev_page_url' : data.prevPageUrl,
          'from'          : data.from,
          'to'            : data.to,
        };

        for (let i = 0; i < data.data.length; i++) {
          const created = moment.utc(data.data[i].created);
          const createdConv = moment(created).local().format('MM/DD/YYYY');
          transformed.playlists.push({
            orderNo       : data.data[i].order_no,
            playlistId    : data.data[i].music_playlist_id,
            thumbnail     : data.data[i].thumbnail_url,
            name          : data.data[i].name,
            premium       : (data.data[i].premium === 1) ? 'Yes' : 'No',
            published     : (data.data[i].published === 1) ? 'Yes' : 'No',
            created       : createdConv,
            displayBanner : data.data[i].display_banner,
            bannerOrderNo : data.data[i].banner_order_no,
          });

          if (data.data[i].order_no > this.maxOrder)
            this.maxOrder = data.data[i].order_no + 1;
        }

        return transformed;
      },

      /**
       * Create New Playlist
       */
      openCreatePlaylist() {
        this.$modal.show(CreatePlaylistModal, {}, {
          height   : 'auto',
          adaptive : true,
        }, {
          'reload-table' : () => {
            this.reloadTable();
          },
        });
      },

      /**
       * Delete Playlist
       * @param data
       */
      deletePlaylist(data) {
        this.$modal.show('dialog', {
          title : 'Delete Playlist',
          text  : `Are you sure you want to delete <b>${data.name}</b>` +
            ` playlist?`,
          buttons : [ {
            title   : 'Yes',
            handler : () => {
              this.showLoader();
              this.$http.delete('api/playlist', {
                data : {
                  plistId : data.playlistId,
                },
              }).then(() => {
                this.$notify({
                  group : 'notif',
                  type  : 'success',
                  title : 'Success!',
                  text  : 'Deleted playlist successfully.',
                });

                if (this.$refs.vuetable.tableData.length === 1)
                  this.refreshTable();
                else
                  this.reloadTable();
                this.$modal.hide('dialog');
              }).catch(() => {
                this.$notify({
                  group : 'notif',
                  type  : 'error',
                  title : 'Failed',
                  text  : 'Oops! Something went wrong!',
                });
              }).finally(() => {
                this.hideLoader();
              });
            },
          }, {
            title : 'No',
          } ],
        })
      },

      /**
       * Refresh Playlist Table
       */
      refreshTable() {
        this.$nextTick(() => {
          this.$refs.vuetable.refresh();
        });
      },

      /**
       * Reload Playlist Table (current page will not reset)
       */
      reloadTable() {
        this.$nextTick(() => {
          this.$refs.vuetable.reload();
        });
      },

      /**
       * Update Paginaton info
       * @param paginationData
       */
      onPaginationData(paginationData) {
        this.$nextTick(() => {
          this.$refs.pagination.setPaginationData(paginationData);
          this.$refs.paginationInfo.setPaginationData(paginationData);
        });
      },

      /**
       * Update Vuetable page
       * @param page
       */
      onChangePage(page) {
        this.$nextTick(() => {
          this.$refs.vuetable.changePage(page);
          this.$router.replace('/admin/playlist/' + page);
        });
      },

      /**
       * Show Loader
       */
      showLoader() {
        this.loading = true;
      },

      /**
       * Hide Loader
       */
      hideLoader() {
        this.loading = false;
      },

      /**
       * Set Alternative Thumbnail
       * @param event
       */
      setAltThumbnail(event) {
        event.target.src =
          require('../../../assets/images/music-album-placeholder.png');
      },

      /**
       * Change Playlist Order
       * @param data
       */
      async changeOrder(data) {
        this.showLoader();
        await this.$http.post('api/playlist/order', {
          mPlaylistId : data.playlistId,
          orderNo     : data.orderNo,
        }).then(() => {
          this.refreshTable();
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success!',
            text  : 'Updated playlist order successfully.',
          });
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        }).finally(() => {
          this.hideLoader();
        });
      },
      async changeBannerOrder(data) {
        this.showLoader();
        await this.$http.post('api/playlist/bannerOrder', {
          mPlaylistId   : data.playlistId,
          bannerOrderNo : data.bannerOrderNo,
        }).then(() => {
          this.refreshTable();
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success!',
            text  : 'Updated banner order successfully.',
          });
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        }).finally(() => {
          this.hideLoader();
        });
      },

      getClass(data) {
        if(data.displayBanner == 0)
          return 'disabled-select';
        return 'enabled';
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/settings";
  .disabled-select {
    opacity: .5;
    pointer-events: none;
    color: transparent;
  }
</style>